<content-container>
  <div content-slot>
    <title-component title="ZASZYFRUJ" [decoration]="true"></title-component>
    <content-component>
      <p>
        Wpisz widomość do zaszyfrowania
      </p>
    </content-component>
    <input-component
      placeholder="Wpisz treść do zaszyfrowania"
      (inputValueChange)="decryptionValue($event)"
    ></input-component>
    <div *ngIf="decryptionItemList.length > 0">
      <title-component subtitle="WYNIKI SZYFROWANIA"></title-component>
      <result-row-list [result]="decryptionItemList"></result-row-list>
    </div>
  </div>
  <div image-slot>
    <img src="/assets/image/bg_image_decryption.png" />
  </div>
</content-container>
