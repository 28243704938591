<div class="result-one-row" [ngClass]="mode ? 'mode--dark' : 'mode--light'">
  <div class="result-one-row__icon">
    <icon-component [idIcon]="iconId"></icon-component>
  </div>

  <div class="result-one-row__content">
    <span class="mode--hidden">
      {{ hiddenResult }}
    </span>
    {{ showResult }}
  </div>
</div>
