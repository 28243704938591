import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'information-page',
  templateUrl: './information-page.component.html',
  styleUrls: ['./information-page.component.scss']
})
export class InformationPageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
