<content-container>
  <div content-slot>
    <title-component
      title="INFORMACJE"
      subtitle="SZYFR CEZARA"
      [decoration]="true"
    ></title-component>
    <content-component>
      <p>
        jedna z najprostszych technik szyfrowania. Jest to rodzaj szyfru
        podstawieniowego, w którym każda litera tekstu jawnego
        (niezaszyfrowanego) zastępowana jest inną, oddaloną od niej o stałą
        liczbę pozycji w alfabecie, literą (szyfr monoalfabetyczny), przy czym
        kierunek zamiany musi być zachowany. Nie rozróżnia się przy tym liter
        dużych i małych. Nazwa szyfru pochodzi od Juliusza Cezara, który
        prawdopodobnie używał tej techniki do komunikacji ze swymi przyjaciółmi.
      </p>
    </content-component>
    <button-component
      content="Wypróbuj"
      routerLink="/zaszyfruj"
    ></button-component>
  </div>
  <div image-slot>
    <img src="/assets/image/bg_image_information.png" />
  </div>
</content-container>
