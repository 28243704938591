<div
  *ngIf="title"
  class="title-component__title"
  [ngClass]="{ 'mod--padding': !subtitle }"
>
  {{ title }}
</div>
<div *ngIf="subtitle" class="title-component__subtitle">{{ subtitle }}</div>
<div *ngIf="decoration" class="title-component__decoration">
  <img src="/assets/svg/decoration.svg" alt="" />
</div>
