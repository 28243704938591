<div class="menu-component__container">
  <div class="menu-component__logo icon-11"></div>

  <nav class="menu-component__nav">
    <button
      *ngFor="let MenuItem of MenuItemList"
      [routerLink]="MenuItem.link"
      routerLinkActive="mod--active"
    >
      {{ MenuItem.displayContent }}
    </button>
  </nav>
</div>
