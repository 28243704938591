<div class="content-container">
  <div class="content-container__content">
    <ng-content select="[content-slot]"></ng-content>
  </div>
  <div class="content-container__container-image">
    <div class="content-container__image">
      <ng-content select="[image-slot]"></ng-content>
    </div>
  </div>
</div>
