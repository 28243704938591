import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'main-page-component',
  templateUrl: './main-page-component.component.html',
  styleUrls: ['./main-page-component.component.scss']
})
export class MainPageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
